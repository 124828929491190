import { Switch, Route } from 'react-router-dom';
import { Spin } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

import { GlobalStyles } from './styles/global.styles';

import { useUsersData } from './hooks/users.hooks';
import { UsersContext } from './contexts/user.context';

import PrivateRoute from './components/common/private-route/private-route.component';
import ErrorBoundary from './components/layout/error-boundary/error-boundary.component';

import { ROLES_TO_PAGE } from './constants/systemRoles';
import React, { Suspense, lazy } from 'react';

const antIcon = <LoadingOutlined style={{ fontSize: 56 }} spin />;
const HomePage = lazy(() => import('./pages/home/home.component'));
const ApprovedEmailThanksPage = lazy(() => import('./pages/approved-email-thanks/approved-email-thanks.component'));
const LoginPage = lazy(() => import('./pages/login/login.component'));
// const InterviewsPage = lazy(() => import('./pages/interviews/interviews.component'));
const CandidatesPage = lazy(() => import('./pages/candidates/candidates.component'));
const EmployeesPage = lazy(() => import('./pages/employees/employess.component'));
const SelectInternshipTime = lazy(() => import('./pages/select-internship-time/select-internship-time.component'));
const TimeTrackingPage = lazy(() => import('./pages/time-tracking/time-tracking.component'));
const TimeSheetsPage = lazy(() => import('./pages/timesheets/timesheets.component'));
const ProjectsPage = lazy(() => import('./pages/projects/projects.component'));
// const DealsPage = lazy(() => import('./pages/deals/deals.component'));
// const ContactPage = lazy(() => import('./pages/contacts/contacts.component.jsx'));
const NotFoundPage = lazy(() => import('./pages/not-found/not-found.component'));
const GoogleSuccess = lazy(() => import('./pages/google-success/google-success.component'));
// const FinancePage = lazy(() => import('./pages/finance/finance.component'));
// const MarketingPage = lazy(() => import('./pages/marketing/marketing.component.jsx'));

const App = () => {
  const { user, levels, isAuthChecking, loginMutation, roles } = useUsersData();

  return (
    <UsersContext.Provider value={{ user, levels, roles }}>
      <GlobalStyles />

      <ErrorBoundary>
        <Suspense fallback={<Spin className="page-loading-spinner-wrap" indicator={antIcon} size="large" />}>
          <Switch>
            <PrivateRoute path="/" rolesForPage={ROLES_TO_PAGE.homePage} exact component={HomePage} />
            {/* <PrivateRoute path="/contacts" rolesForPage={ROLES_TO_PAGE.contactPage} exact component={ContactPage} />
            <PrivateRoute path="/deals" rolesForPage={ROLES_TO_PAGE.dealPage} exact component={DealsPage} /> */}
            <PrivateRoute
              path="/candidates"
              rolesForPage={ROLES_TO_PAGE.candidatesPage}
              exact
              component={CandidatesPage}
            />
            <PrivateRoute
              path="/employees"
              rolesForPage={ROLES_TO_PAGE.employeesPage}
              exact
              component={EmployeesPage}
            />
            {/* Hide because of unuse */}
            {/* <PrivateRoute
              path="/interviews"
              rolesForPage={ROLES_TO_PAGE.interviewsPage}
              exact
              component={InterviewsPage}
            /> */}
            <PrivateRoute
              path="/timeTracking"
              rolesForPage={ROLES_TO_PAGE.timeTrackingPage}
              exact
              component={TimeTrackingPage}
            />
            <PrivateRoute path="/timesheets" rolesForPage={ROLES_TO_PAGE.timesheets} exact component={TimeSheetsPage} />
            <PrivateRoute path="/projects" rolesForPage={ROLES_TO_PAGE.projectsPage} exact component={ProjectsPage} />
            {/* <PrivateRoute path="/leads" rolesForPage={ROLES_TO_PAGE.marketingPage} exact component={MarketingPage} />

            <PrivateRoute path="/finance" rolesForPage={ROLES_TO_PAGE.financePage} exact component={FinancePage} /> */}
            <Route path="/google/success" component={GoogleSuccess} exact />
            <Route path="/login" exact>
              <LoginPage isAuthChecking={isAuthChecking} login={loginMutation} />
            </Route>
            <Route path="/approveemail/thanks" exact component={ApprovedEmailThanksPage} />
            <Route path="/candidates/select-internship-time" exact component={SelectInternshipTime} />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </UsersContext.Provider>
  );
};

export default App;
